<template>
  <div class="account-detail">
    <div v-if="type==='deposit'">
      <h3 v-text="type==='withdraw'?'Withdraw detail':'Deposit detail'" />
      <b-row class="mt-2">
        <b-col
          cols="24"
          md="12"
        >
          <b-card title="Basic Info">
            <b-col cols="24 pt-0">
              <hr>
            </b-col>
            <div class="basic-content">
              <div>
                <b-card-text>Deposit ID: </b-card-text>
                <b-card-text>User Account: </b-card-text>
              </div>
              <div>
                <b-card-text>Amount: </b-card-text>
                <b-card-text>status: </b-card-text>
              </div>
              <div>
                <b-card-text>Arrival Amount: </b-card-text>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="24"
          md="12"
        >
          <b-card title="Payer Bank Info">
            <b-col cols="24 pt-0">
              <hr>
            </b-col>
            <div class="basic-content">
              <div>
                <b-card-text>Bank Country: </b-card-text>
                <b-card-text>Account Number: </b-card-text>
              </div>
              <div>
                <b-card-text>Account Name: </b-card-text>
                <b-card-text>Bank Branch: </b-card-text>
              </div>
              <div>
                <b-card-text>Bank Name: </b-card-text>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="type==='withdraw'">
      <h3 v-text="type==='withdraw'?'Withdraw detail':'Deposit detail'" />
      <b-row class="mt-2">
        <b-col
          cols="24"
          md="12"
        >
          <b-card title="Basic Info">
            <b-col cols="24 pt-0">
              <hr>
            </b-col>
            <div class="basic-content">
              <div>
                <b-card-text>Deposit ID: </b-card-text>
                <b-card-text>User Account: </b-card-text>
              </div>
              <div>
                <b-card-text>Amount: </b-card-text>
                <b-card-text>status: </b-card-text>
              </div>
              <div>
                <b-card-text>Arrival Amount: </b-card-text>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="24"
          md="12"
        >
          <b-card title="Beneficiary Bank Info">
            <b-col cols="24 pt-0">
              <hr>
            </b-col>
            <div class="basic-content">
              <div>
                <b-card-text>Bank Country: </b-card-text>
                <b-card-text>Account Number: </b-card-text>
              </div>
              <div>
                <b-card-text>Account Name: </b-card-text>
                <b-card-text>Bank Branch: </b-card-text>
              </div>
              <div>
                <b-card-text>Bank Name: </b-card-text>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  // BTable,
  // BForm,
  // BFormInput,
  // BButton,
} from 'bootstrap-vue'
// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// // Use toast
// const toast = useToast()
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    // BTable,
    // BForm,
    // vSelect,
    // BFormInput,
    // BButton,
  },
  data() {
    return {
      basicInfo: {},
      userInfo: {},
      history: [],
      type: '',
    }
  },
  computed: {},
  mounted() {
    this.initDepositDetail()
  },
  methods: {
    // 初始化deposit详情
    initDepositDetail() {
      // this.$showLoading();
      this.type = this.$route.query.type
      // console.log('typetypetypetype', this.type)
      // this.$http
      //   .get(`/api/v1/deposit/details?transactionId=${id}`)
      //   .then((response) => {
      //     this.$hideLoading();
      //     if (response.data.code == 200) {
      //       const { basicInfo, userInfo, history } = response.data.data;
      //       this.basicInfo = basicInfo;
      //       this.userInfo = userInfo;
      //       this.history = this.handlerHistory(history);
      //     } else {
      //       this.showToast("warning", response.data.message);
      //     }
      //   })
      //   .catch((error) => {
      //     this.$hideLoading();
      //     this.showToast("warning", error.message);
      //   });
    },
    showToast(variant = null, message = '') {
      this.$bvToast.toast(`${message}`, {
        title: `${variant || 'Error'}`,
        variant,
        solid: true,
        toaster: 'b-toaster-top-center',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.account-detail {
  width: 80%;
  padding: 3rem 4rem;
  margin: 0 auto;
  min-height: 400px;
  .basic-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  ::v-deep .history-table {
    word-break: break-all;
    word-wrap: break-all;
    .table th,
    .table td {
      padding: 10px !important;
    }
    .table tr td:nth-of-type(1) {
      width: 7.2rem;
    }
    .table tr td:nth-of-type(2) {
      width: 12.7rem;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .userinfo-form label {
    width: 100px;
    justify-content: left;
  }
  .invoice-filter-select {
    min-width: 280px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .d-inline-block {
    width: 280px;
  }
}
</style>
